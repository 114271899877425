<template>
  <v-container fluid class="main-red-bg d-flex justify-center ">
    <div class="white--text text-center">
      <h5 class="font-weight-regular">
        © Copyright 2021. All Rights Reserved. Analytica Business Solutions.
      </h5>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
};
</script>
